import Index from "./Index";
import BaseGuide from './base/Guide';
// 配置
import Alignment from "./components/integration/Alignment";
// 产品经理
import PMGuide from "./components/pm/Guide";
import PMSimpleGuide from "./components/pm/SimpleGuide";
import PMDashboard from './components/pm/Dashboard';
import PMGrowthDashboard from './components/pm/growth/Dashboard';
import PMRetention from './components/pm/Retention'
import PMFunnel from './components/pm/Funnel'
import PMActiveDistribute from './components/pm/ActiveDistribute'
// AB测
import ABTestGuide from './components/pm/abtest/Guide';
import ExpList from "./components/pm/abtest/ExpList";
import LayerList from "./components/pm/abtest/LayerList";
import ParamsList from "./components/pm/abtest/ParamsList";
// 指标
import MetricIndex from "./components/pm/metric/MetricIndex";
// 埋点管理
import AnchorGuide from './components/pm/anchor/Guide';

// 用户时长
import Engagement from "./components/pm/Engagement";
// 获客量级
import UASimulate from './components/pm/UASimulate'
// 指标分析
import MetricAnalysis from "./components/pm/MetricAnalysis";
// LTV
import LTPredict from "./components/pm/LTPredict";
// Archive
import ArchiveIndex from './components/pm/archive/Index'
import ArchiveVersionPanel from './components/pm/archive/VersionPanel'
// UA
import UGDashboard from './components/ua/UserGrowth.vue';
import UADashboard from './components/ua/Dashboard';
import UAFacebook from "./components/ua/Facebook";
import UAGoogleAds from './components/ua/GoogleAds';
// IAA
import IAADashboard from './components/iaa/Dashboard';
import IAAPlacement from './components/iaa/Placement';
import IAASdk from './components/iaa/Sdk';
import Waterfall from "./components/iaa/Waterfall";
// IAP
import IAPOrder from './components/iap/Order';
import IAPSubscription from './components/iap/Subscription';
//Supervisor
import SupervisorDashboard from './components/supervisor/Dashboard';
import CY from './components/supervisor/CY';
// Push
import Push from './components/pm/Push';
import TUserGrowth from "./components/transsion/TUserGrowth.vue";
import TDashboard from "./components/transsion/TDashboard.vue";
import Update from "./components/pm/Update.vue";

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
        title: '产品列表'
    }, {
        name: 'Alignment',
        path: '/alignment',
        component: Alignment,
        meta: {title: '数据对齐'},
    },
    // 产品
    {
        name: 'PMGuide',
        path: '/pm/:productId',
        component: top.location.hostname === 'bi.bidderdesk.com' ? PMSimpleGuide : PMGuide,
        meta: {title: '产品'},
        children: [
            {
                name: 'PMDashboard',
                path: 'dashboard',
                meta: {title: '默认看板'},
                component: PMDashboard,
            }, {
                name: 'PMGrowthDashboard',
                path: 'growth/dashboard',
                component: PMGrowthDashboard,
                meta: {title: '自定义看板'},
            }, {
                name: 'PMRetention',
                path: 'retention',
                component: PMRetention,
                meta: {title: '留存报告'},
            }, {
                name: 'PMFunnel',
                path: 'funnel',
                component: PMFunnel,
                meta: {title: '漏斗模型'}
            }, {
                name: 'ActiveDistribute',
                path: 'active/distribute',
                component: PMActiveDistribute,
                meta: {title: '日活分布'},
            }, {
                name: 'ABTest',
                path: 'abtest',
                component: ABTestGuide,
                meta: {title: 'AB测'},
                redirect: {name: 'ExpList', query: {domain: 'elastic-test'}},
                children: [
                    {name: 'ExpList', path: 'exp', component: ExpList},
                    {name: 'LayerList', path: 'layer', component: LayerList},
                    {name: 'ParamsList', path: 'params', component: ParamsList},
                ]
            }, {
                name: 'AnchorGuide',
                path: 'anchor',
                component: AnchorGuide,
                meta: {title: '埋点管理'},
            }, {
                name: 'DWMetric',
                path: 'dw/metric',
                component: MetricIndex,
                meta: {title: '数仓指标'},
            }, {
                name: 'UASimulate',
                path: 'ua/simulate',
                component: UASimulate,
                meta: {title: '获客量级模拟'},
            }, {
                name: 'Engagement',
                path: 'engagement',
                component: Engagement,
                meta: {title: '用户时长分析'},
            }, {
                name: 'MetricAnalysis',
                path: 'metric/analysis',
                component: MetricAnalysis,
                meta: {title: '指标分析'},
            }, {
                name: 'LTPredict',
                path: 'lt/predict',
                component: LTPredict,
                meta: {title: 'LT预测模型'},
            }, {
                name: 'ArchiveIndex',
                path: 'archive',
                component: ArchiveIndex,
                meta: {title: '版本列表'},
            }, {
                name: 'ArchiveVersionPanel',
                path: 'archive/version/:versionId',
                component: ArchiveVersionPanel,
                meta: {title: '版本详情'},
            }, {
                name: 'Push',
                path: 'push',
                component: Push,
                meta: {title: '消息推送'}
            }, {
                name: 'UAGuide',
                path: 'ua',
                component: BaseGuide,
                redirect: 'UADashboard',
                children: [
                    {
                        name: 'UADashboard',
                        path: 'dashboard',
                        component: UADashboard,
                        meta: {title: '投放总览'},
                    }, {
                        name: 'UAFacebookDashboard',
                        path: 'facebook',
                        component: UAFacebook,
                        meta: {title: 'Facebook投放'},
                    }, {
                        name: 'UAGoogleAdsDashboard',
                        path: 'googleads',
                        component: UAGoogleAds,
                        meta: {title: 'GoogleAds投放'},
                    }, {
                        name: 'UGDashboard',
                        path: 'ug',
                        component: UGDashboard,
                        meta: {title: '用户增长'},
                    }
                ]
            }, {
                name: 'RevenueGuide',
                path: 'revenue',
                redirect: {name: 'IAADashboard'},
                component: BaseGuide,
                children: [
                    {
                        name: 'IAADashboard',
                        path: 'dashboard',
                        component: IAADashboard,
                        meta: {title: '广告总览'},
                    }, {
                        name: 'IAAPlacement',
                        path: 'placement',
                        component: IAAPlacement,
                        meta: {title: '广告位分析'},
                    }, {
                        name: 'IAASdk',
                        path: 'sdk',
                        component: IAASdk,
                        meta: {title: '广告配置'},
                    }, {
                        name: 'IAPOrder',
                        path: 'order',
                        component: IAPOrder,
                        meta: {title: '内购订单'}
                    },
                    {
                        name: 'IAPSubscription',
                        path: 'subscription',
                        component: IAPSubscription,
                        meta: {title: '订阅留存'}
                    }, {
                        name: 'Waterfall',
                        path: 'waterfall',
                        component: Waterfall,
                        meta: {title: '瀑布流'},
                    },
                ]
            }, {
                name: 'TGuide',
                path: 'transsion',
                component: BaseGuide,
                redirect: 'TDashboard',
                children: [
                    {
                        name: 'TDashboard',
                        path: 'dashboard',
                        component: TDashboard,
                        meta: {title: '默认看板'},
                    }, {
                        name: 'TUserGrowth',
                        path: 'ug',
                        component: TUserGrowth,
                        meta: {title: '用户增长'},
                    }
                ]
            }, {
                name: 'Update',
                path: 'update',
                component: Update,
                meta: {title: '版本管理'},
            }
        ]
    }, {
        name: 'SupervisorDashboard',
        path: '/supervisor/dashboard',
        component: SupervisorDashboard,
    }, {
        name: 'CY',
        path: '/cy',
        component: CY,
    },
];